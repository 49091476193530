/* eslint-disable no-param-reassign */
import store from '@/store';
import { busService } from '@/utility';
import { uuid } from 'vue3-uuid';
import ticketAPI from '../api/ticket';
/**
 * Prepare bet for betslip (games-sdk)
 * @returns {object} return new bet
 */
const createBet = (bet) => {
  const eventId = store.getters.eventInProgress ? Number(store.state.eventId) + 1 : store.state.eventId;
  // dummy Bet
  const newBet = {
    key: uuid.v4(),
    displayId: eventId,
    roundNumber: Number(eventId),
    market: store.getters.getTranslation('crash_cash_market'),
    outcome: `x${bet.outcome}`,
    odds: Number(bet.outcome),
    stake: bet.stake,
    locked: false,
    marketId: bet.marketId,
    hideOdds: true,
    numEvents: 1,
  };
  return newBet;
};

/**
 * Prepare bets for ticket payin action
 * @param bets {Array}
 * @param payin {Number}
 * @returns {Array}
 */
const prepareBets = (bets) => {
  const newBets = [];

  for (let i = 0; i < bets.length; i++) {
    const { odds, numEvents } = bets[i];

    for (let j = 0; j < numEvents; j++) {
      newBets.push({
        payIn: {
          real: +bets[i].stake,
          bonuses: [],
        },
        selections: [
          {
            marketId: 1,
            outcomeId: odds.toString(),
            eventId: store.getters.upcomingEvents[j]?.eventId,
            displayId: store.getters.upcomingEvents[j]?.displayId,
          },
        ],
        selectedSystem: 1,
      });
    }
  }

  return newBets;
};
/**
 * Prepares the ticket data
 * @returns {object} return ticket data object
 */
const prepareTicketData = (data = {}) => {
  const { config } = store.getters;
  const { productName } = config;
  const payment = store.getters['gamesBetslip/totalPayment'];
  const tickets = store.getters['gamesBetslip/ticket'];
  const bets = prepareBets(tickets);
  const metadata = prepareMetadata(config, data?.requestUuid);
  const additionalInfo = {
    isWebTerminal: store.state.isWebTerminal,
  };
  return {
    payment,
    tickets,
    bets,
    productName,
    metadata,
    additionalInfo,
  };
};

/**
 * Prepare metadata for ticket payin action
 * @returns {Object}
 */
const prepareMetadata = (config) => {
  const metadata = {
    appDeviceUuid: store.getters.deviceId,
    cpvUuid: config.producInstanceUuid,
    deliveryPlatform: 'Web',
    paymentMethod: 'VirtualMoney',
    product: 'CrashCash',
    requestUuid: uuid.v4(),
    sources: [],
  };
  return metadata;
};

/**
 * Create 'seven' ticket data for payin action
 * @returns {Object} return new ticket data object
 */
const createSevenTicket = () => {
  const { bets, totalPayment } = prepareTicketData();

  return {
    stake: totalPayment,
    bets,
  };
};
/**
 * Payin flow depends on the flag for 'directPayin' over product or payin over platform
 */
const ticketPayin = async () => {
  if (store.getters.isWebTerminal) {
    const ticket = createSevenTicket();
    const data = createProductTicket(ticket);
    ticketAPI.payinTicket(data);
  } else {
    const ticket = createSevenTicket();
    const ticketPayinData = {
      action: 'Tickets.Pay',
      data: {
        validateTicketPayinLimit: false,
        ticket,
      },
    };
    await busService.sendMessageAsync(ticketPayinData.action, ticketPayinData.data);
  }
};
/**
 * Create 'product' ticket data for payin action
 * @returns {Object} return new ticket data object
 */
const createProductTicket = (data = {}) => {
  const { bets, metadata, additionalInfo } = prepareTicketData(data);
  const validateTicketPayinLimit = false;
  const ticketData = {
    ticket: { bets },
    metadata,
    validateTicketPayinLimit,
    additionalInfo,
  };
  return ticketData;
};

/**
 * Prepare an array of bet selections based on the 'selectionRefs'
 * @returns {Array}
 */
const prepareBetSelections = (data) => {
  const { selections, bets } = data;
  const { config } = store.state;

  bets.forEach((bet) => {
    const betSelections = bet.selectionRefs.map(({ selectionIndex }) => {
      const selection = selections[selectionIndex] || {};
      return selection;
    });

    bet.selections = betSelections;
    const maxPossibleWin = bet.winnings.maxPossible;
    bet.cappedWinnings =
      config.rawData.maxPossibleWin < maxPossibleWin ? config.rawData.maxPossibleWin : maxPossibleWin;

    // Bet totals extraction
    bet.betPayment = bet.payment?.real?.value;
    bet.betTax = bet.taxes?.filter((tax) => tax.type === 'PAYIN').reduce((total, tax) => total + tax.value, 0);
    bet.betStake = bet.stake?.real?.value;
    bet.betWinnings = bet.winnings?.total?.value;
    bet.maximumWinnings = bet.winnings?.maxPossible;
    bet.minimumWinnings = bet.winnings?.minPossible;
    bet.betPayout = bet.payout?.real?.value;
    bet.payoutTax = bet.possiblePayout?.taxes?.reduce((total, tax) => total + tax.value, 0);
    bet.maximumPayout = bet.maximumPossiblePayout?.real?.value;
    bet.maximumPayoutTax = bet.maximumPossiblePayout?.taxes
      ?.filter((tax) => tax.type === 'PAYOUT')
      .reduce((total, tax) => total + tax.value, 0);
    bet.minimumPayout = bet.minimumPossiblePayout?.real?.value;
    bet.minimumPayoutTax = bet.minimumPossiblePayout?.taxes
      ?.filter((tax) => tax.type === 'PAYOUT')
      .reduce((total, tax) => total + tax.value, 0);
  });

  return bets;
};
/**
 * Prepare an object of 'seven' ticket response data
 * @returns {Object}
 */
const prepareSevenTicketData = (data) => {
  // eslint-disable-next-line no-console
  console.log(' prepareSevenTicketData ', data);
  const { config } = store.state;
  const { totals, codes, productName, requestId, createdAt, status, bets } = data;
  const { taxes, payment, payout, stake, possiblePayout, maximumPossiblePayout } = totals;
  const payinTaxes = taxes?.filter((tax) => tax.type === 'PAYIN');
  const payinTax = payinTaxes.reduce((sum, tax) => sum + tax.value, 0);
  const payoutTaxes = taxes?.filter((tax) => tax.type === 'PAYOUT');
  const payoutTax = payoutTaxes.reduce((sum, tax) => sum + tax.value, 0);

  const winnings = bets.reduce((totalWinnings, bet) => totalWinnings + (bet.winnings?.total.value || 0), 0);
  const ticketPin = data.ticketPin || data.additionalDetails?.ticketPin || null;

  const luckyMultipliers = data.meta?.luckyMultipliers || null;
  const luckyMultiplier = luckyMultipliers ? JSON.parse(luckyMultipliers)[0]?.luckyMultiplier : null;
  const id = codes?.find((code) => code.type === 'barcode')?.id;
  const payoutValue = status === 'WON' ? possiblePayout.real.value : payout.real.value;

  const maxPossibleWin = totals.winnings.maxPossible || null;
  const minPossibleWin = totals.winnings.minPossible || null;
  const maxPossiblePayout = maximumPossiblePayout?.real.value || null;
  const maxPossiblePayoutTax = maximumPossiblePayout?.taxes?.reduce((acc, tax) => {
    return tax.type === 'PAYOUT' ? acc + tax.value : acc;
  }, 0);
  const minPossiblePayout = totals.minimumPossiblePayout?.real?.value || null;
  const minPossiblePayoutTax = totals.minimumPossiblePayout?.taxes?.reduce((acc, tax) => {
    return tax.type === 'PAYOUT' ? acc + tax.value : acc;
  }, 0);
  const cappedWinnings = config.rawData.maxPossibleWin * bets.length;
  const totalCappedWinnings = cappedWinnings < maxPossibleWin ? cappedWinnings : maxPossibleWin;
  return {
    id,
    paymentId: '',
    product: productName,
    payin: payment.real.value,
    payout: payoutValue,
    payoutTax,
    payinTax,
    possiblePayout: payoutValue,
    possiblePayoutTax: undefined,
    requestUuid: requestId,
    createdAt: createdAt,
    stake: stake.real.value,
    status: {
      value: status,
    },
    ticketPin,
    winnings: winnings,
    maxPossibleWin,
    minPossibleWin,
    maxPossiblePayoutTax,
    maxPossiblePayout,
    minPossiblePayout,
    minPossiblePayoutTax,
    luckyMultiplier,
    bets: prepareBetSelections(data),
    isCopy: data.isCopy || '',
    totalCappedWinnings,
  };
};

const mapBetGuids = (data) => {
  const { ticket } = data;
  ticket.bets = ticket.bets.map((bet) => {
    return { ...bet, guid: bet.additionalDetails.taxId };
  });
};

export default {
  createBet,
  ticketPayin,
  prepareBetSelections,
  prepareSevenTicketData,
  mapBetGuids,
};
