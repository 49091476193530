import { BusService } from '@nsftx/games-bus';
import { isEmpty, omit, each, isNil } from 'lodash';
import store from '@/store';
import ticketCheck from './ticketCheck';
import { ws } from '../api';

let busService;

export default {
  /**
   * Start Bus service
   */
  async init() {
    const { config, deviceId } = store.getters;
    const channels = `NSoft.InstanceId.${config.productInstanceUuid},NSoft.SubscriptionId.${config.productId},NSoft.Device.${deviceId}`;
    const headers = {
      'X-NSFT-NGS-CHANNELS': channels,
      'X-NSFT-NGS-SUBSCRIPTIONID': `${config.productId}`,
      'X-NSFT-NGS-DEVICEID': `${deviceId}`,
    };
    let grantCode;
    try {
      store.dispatch('setSocketReInitInProgress', true);
      const { code } = await ws.getCodes(headers);
      grantCode = code;
    } catch (error) {
      store.dispatch('setSocketReInitInProgress', false);
      console.warn('ERROR in getting WS grant code', error);
    }

    const settings = {
      environment: config.environment,
      platform: config.platform,

      platformName: config.platformName,
      productName: config.productName,
      productId: config.productId, // config.productInstanceUuid
      tenantId: config.tenantId,
      locale: config.locale,
      applicationName: config.applicationName,
      useCommonEventName: true,
      adapters: [
        {
          name: 'UWSAdapter',
          options: { maxRetryAttempts: 10, grantCode },
        },
      ],
      channels: [],
      clientMessageProtocol: config.messageProtocol,
      productInstanceId: config.productInstanceUuid,
    };

    // Add device channel to listen to 'ticketUpdate' message
    // settings.channels.push({
    //   type: 'Device',
    //   name: deviceId,
    //   adapter: 'UWSAdapter',
    // });

    try {
      busService = new BusService(omit(settings, ['channels']));

      each(settings.channels, (channel) => {
        busService.addChannel(channel.type, channel.name, channel.adapter);
      });
      busService.start();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Bus service error!', error);
    }

    busService.sendMessage(
      {
        action: 'Slave.Loaded',
      },
      true,
    );
  },
  /**
   * Send message to bus service
   * @param {string} action
   * @param {object} data
   */
  sendMessage(action, data) {
    busService?.sendMessage(
      {
        action,
        data,
      },
      true,
    );
  },
  sendMessageAsync(action, data) {
    busService?.clientAdapter.handler
      .emitAsync({
        action,
        data,
      })
      .then((response) => {
        const { ticket } = response;
        store.dispatch('setTicketAction', ticket.action);
        if (ticket.localStatus === 'PENDING') {
          ticketCheck.startTicketChecker(ticket);
        } else {
          ticketCheck.stopTicketChecker(ticket);
        }
      })
      .catch((error) => {
        const listOfErrorCodesToIgnore = ['T_TICKET_RESOLVE_BACKEND_ERROR'];
        if (!isEmpty(error) && listOfErrorCodesToIgnore.indexOf(error.clientCode) > -1) {
          const notification = {
            type: 'warning',
            message: store.getters.getTranslation(error.serverResponse?.message),
          };
          if (notification.message) {
            // store.dispatch('closeGGMessage');
            store.dispatch('sendGGMessage', notification);
          }
        }
      });
  },
  addChannel(channelType, channelName) {
    busService.addChannel(channelType, channelName);
  },
  /**
   * Disconnect adapters during reconnect etc.
   */
  disconnectAdapters(message) {
    try {
      const uwsAdapter = busService.getAdapter('UWSAdapter');
      if (!isNil(uwsAdapter)) {
        uwsAdapter.disconnect(message);
      }
    } catch (error) {
      console.error('Unsuccesfull adapters disconnect', error);
    }
  },
};
