export default {
  config: {},
  translationDomains: [],
  messaging: 'websocket',
  gameName: 'CrashCash',
  selectedBet: {
    stake: 1,
    market: '',
    outcome: '2.00',
    roundNumber: null,
    marketId: 1, // only one market for CC
  },
  minOutcome: 1.01,
  eventId: null,
  taxes: {
    payin: {},
    payout: {},
  },
  // Available values on 7platform: Add, Cancel, Payout
  ticketActionMap: {
    PayIn: 'Add',
  },
  ticketHistoryFetchData: {},
  ticketHistory: {},
  statusTranslations: {
    closed: 'general_ticket_status_closed',
    accepted: 'general_ticket_status_accepted',
    lost: 'general_ticket_status_lost',
    won: 'general_ticket_status_won',
    expired: 'general_ticket_status_expired',
    canceled: 'general_ticket_status_canceled',
    cancelled: 'general_ticket_status_canceled',
    paidout: 'general_ticket_status_paidout',
  },
  printLayoutType: {
    add: 'ticketCrashCash',
    payout: 'ticketCrashCashPayout',
    cancel: 'ticketCrashCashCancel',
    results: 'resultsCrashCash',
  },
  actionsThatHavePrintOnlyOnTBO: ['Cancel', 'Payout'],
  actionTypes: {
    add: 'TicketPayIn',
    payout: 'TicketPayout',
    cancel: 'TicketCancel',
    copy: 'TicketCopy',
  },
  activeComponent: 'BettingArea',
  activeComponents: ['BettingArea', 'TicketCheck'],
  stakeValue: '0.00',
  pickValue: '0.00',
  autoTicketCheckTimeout: 5000,
  results: [],
  terminalSettings: {},
  stakeValues: [
    // default values
    1.0, 1.5, 2.0, 3.0, 5.0, 10.0, 20.0, 25.0, 50.0, 100.0,
  ],
  pickValues: [
    // default values
    1.25, 1.5, 1.8, 2.0, 2.5, 5.0, 10.0, 25.0, 50.0, 100.0,
  ],
  gameActive: true,
  errorMessage: '',
  integration: false,
  token: '',
  appShouldCountinueInit: true,
  payinAllowed: true,
  isWebTerminal: false,
  arrowVisible: false,
  isBettingDisabled: false,
  ticketHistoryFetchInProgress: false,
  isOutcomeSelected: true,
  connectionReconnectActive: true,
  reconnectAtemptCounter: 0,
  maxReconnectAtemptNumber: 10,
  upcomingEvents: [],
  socketReInitInProgress: false,
};
