export default {
  SET_CONFIG: 'SET_CONFIG',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  CLEAR_STAKE: 'CLEAR_STAKE',
  SET_STAKE: 'SET_STAKE',
  SET_OUTCOME: 'SET_OUTCOME',
  SET_EVENT_TIME: 'SET_EVENT_TIME',
  SET_EVENT_ID: 'SET_EVENT_ID',
  SET_EVENT_NAME: 'SET_EVENT_NAME',
  SET_TAXES: 'SET_TAXES',
  SET_MARKET: 'SET_MARKET',
  SET_DEVICE: 'SET_DEVICE',
  SET_BETSHOP: 'SET_BETSHOP',
  SET_COMPANY: 'SET_COMPANY',
  SET_ACCOUNT: 'SET_ACCOUNT',
  SET_TERMINAL_USER_DATA: 'SET_TERMINAL_USER_DATA',
  SET_TICKET_CHECK_DATA: 'SET_TICKET_CHECK_DATA',
  CLEAR_TIKCET_CHECK_DATA: 'CLEAR_TIKCET_CHECK_DATA',
  SET_TICKET_HISTORY_FETCH_DATA: 'SET_TICKET_HISTORY_FETCH_DATA',
  SET_TICKET_HISTORY: 'SET_TICKET_HISTORY',
  SET_ACTIVE_COMPONENT: 'SET_ACTIVE_COMPONENT',
  SET_STAKE_VALUE: 'SET_STAKE_VALUE',
  SET_PICK_VALUE: 'SET_PICK_VALUE',
  SET_TICKET_ACTION: 'SET_TICKET_ACTION',
  SET_RESULTS: 'SET_RESULTS',
  SET_TERMINAL_SETTINGS_DATA: 'SET_TERMINAL_SETTINGS_DATA',
  SET_STAKE_VALUES: 'SET_STAKE_VALUES',
  SET_PICK_VALUES: 'SET_PICK_VALUES',
  SET_GAME_NOT_ACTIVE: 'SET_GAME_NOT_ACTIVE',
  SET_DEVICE_ID: 'SET_DEVICE_ID',
  SET_IS_WEB_TERMINAL: 'SET_IS_WEB_TERMINAL',
  SET_TOKEN: 'SET_TOKEN',
  SET_APP_SHOULD_CONTINUE_INIT: 'SET_APP_SHOULD_CONTINUE_INIT',
  SET_ARROWS_VISIBILITY: 'SET_ARROWS_VISIBILITY',
  SET_BETTING_DISABLED: 'SET_BETTING_DISABLED',
  SET_HISTORY_FETCH_IN_PROGRESS: 'SET_HISTORY_FETCH_IN_PROGRESS',
  SET_OUTCOME_SELECTED: 'SET_OUTCOME_SELECTED',
  SET_RECONNECT_STATE: 'SET_RECONNECT_STATE',
  INCREASE_RECONNECT_ATEMPTS: 'INCREASE_RECONNECT_ATEMPTS',
  RESET_RECONNECT_ATEMPTS_COUNTER: 'RESET_RECONNECT_ATEMPTS_COUNTER',
  SET_UPCOMING_EVENTS: 'SET_UPCOMING_EVENTS',
  SET_SOCKET_REINIT_IN_PROGRESS: 'SET_SOCKET_REINIT_IN_PROGRESS',
};
