<template>
  <div class="app" :class="{ 'hide-cursor': !cursorEnabled, integration: isWebTerminal }">
    <router-view v-if="gameActive"></router-view>
    <Errorpage v-else :description="errorMessage"> </Errorpage>
  </div>
</template>
<script>
import applyPalette from '@nsftx/games-sdk-js/src/utility/applyPalette';
import { merge } from 'lodash';
import { mapGetters, mapState, mapActions } from 'vuex';
import { defineAsyncComponent } from 'vue';
import darkTheme from './assets/style/themes/dark';
import { eventBus } from './utility';

export default {
  name: 'App',
  components: {
    Errorpage: defineAsyncComponent(() =>
      import(/* webpackChunkName: "ErrorPage" */ '@nsftx/games-sdk-js/src/components/TerminalGameErrorPage'),
    ),
  },
  computed: {
    ...mapGetters([
      'config',
      'cursorEnabled',
      'gameActive',
      'isBettingDisabled',
      'translations',
      'connectionReconnectActive',
      'reconnectLimitReached',
    ]),
    ...mapState(['errorMessage', 'isWebTerminal']),
  },
  mounted() {
    eventBus.$on('reInitSocketConnection', this.reInitSocketConnectionAction);
    if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
      // eslint-disable-next-line no-console
      console.log('Store: ', this.$store);
    }
    if (this.gameActive) this.setTheme();
  },
  methods: {
    ...mapActions(['reInitBusService', 'setBettingDisabled']),
    ...mapActions('gamesBetslip', ['setIsPayinButtonDisabled']),
    ...mapActions('notifications', ['clearNotification', 'setNotification']),
    setTheme() {
      const rootElement = document.documentElement;
      const { config } = this.config.ui;
      const baseColor = this.config.ui[config.baseColor];
      const theme = darkTheme;
      applyPalette.set(merge(baseColor, theme), rootElement);
    },
    reInitSocketConnectionAction() {
      if (this.connectionReconnectActive) {
        this.reInitBusService();
      }
    },
  },
  watch: {
    isBettingDisabled(newValue) {
      this.setIsPayinButtonDisabled(newValue);
      if (newValue) {
        const message = {
          message: this.translations.general_betting_disabled,
          notificationTimeout: false,
          type: 'warning',
        };
        this.clearNotification().then(() => {
          this.setNotification(message);
        });
      } else {
        this.clearNotification();
      }
    },
    reconnectLimitReached(newValue) {
      if (newValue) {
        this.setIsPayinButtonDisabled(true);
        // TODO: Remove after seven fixed payin confirmation defect
        this.setBettingDisabled(true);
      }
    },
  },
  onUnmounted() {
    eventBus.$off('reInitSocketConnection', this.reInitSocketConnectionAction);
  },
};
</script>
<style lang="scss">
@import './assets/style/main.scss';
#app,
.app {
  height: 100%;
  width: 100%;
}
.hide-cursor {
  * {
    cursor: none !important;
  }
}
</style>
