import axios from 'axios';
import store from '../store';
import { getApiRoute } from '../../config';

/*
Change baseURL to suit your project needs.
*/
const http = axios.create({
  timeout: 5000,
});

http.interceptors.request.use((config) => {
  // Third party login for web terminal integration
  // standard login for ticket check requests
  const aggregatorURL = getApiRoute('baseURL');
  const socketURL = getApiRoute('webSocketUrl');
  const subscriptionId = store.getters.config?.productId;
  if (config.url.includes(aggregatorURL)) {
    const { uuid, password } = store.getters.account;
    const { deviceId } = store.getters;
    const token = store.getters.terminalUser?.auth?.token;
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers['Authorization'] = `Bearer ${token}`;
    } else if (uuid && password) {
      const basicAuthToken = btoa(`${uuid}:${password}`);
      // eslint-disable-next-line no-param-reassign
      config.headers['Authorization'] = `Basic ${basicAuthToken}`;
      // eslint-disable-next-line no-param-reassign
      config.headers['X-Nsft-Ngs-deviceId'] = deviceId;
    }
  } else if (config.url.includes(socketURL)) {
    const token = store.getters.terminalUser?.auth?.token;
    const { deviceId } = store.getters;
    const { uuid, password } = store.getters.account;

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      const basicAuthToken = btoa(`${uuid}:${password}`);
      // eslint-disable-next-line no-param-reassign
      config.headers['Authorization'] = `Basic ${basicAuthToken}`;
      // eslint-disable-next-line no-param-reassign
      config.headers['X-NSFT-NGS-SUBSCRIPTIONID'] = subscriptionId;
      // eslint-disable-next-line no-param-reassign
      config.headers['X-NSFT-NGS-DEVICEID'] = deviceId;
    }
  }
  return config;
});
export default http;
