import { createApp } from 'vue';
import App from './App';
import router from './router';
import store from './store';
import { sentry, busService } from './utility';
// const allowedOrigins = ['https://staging.seven-terminal.7platform.net', 'https://seven-terminal.7platform.net'];
const app = createApp({
  extends: App,
});
app.config.productionTip = false;

if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
  sentry.start(app);
}
(async () => {
  window.addEventListener('message', (event) => {
    store.dispatch('handleTerminalMessages', event);
    // if (allowedOrigins.includes(event.origin)) {
    // } else {
    //   console.warn('Invalid origin', event.origin);
    // }
  });
  window.addEventListener('beforeunload', () => {
    busService.disconnectAdapters('Session completed successfully');
  });
  window.addEventListener('pagehide', () => {
    busService.disconnectAdapters('Terminal iframe destroy due to inactivity');
  });
  window.addEventListener('contextmenu', (event) => event.preventDefault());
  await store.dispatch('initApp');
  app.use(router).use(store).mount('#app');
})();
