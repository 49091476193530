export default {
  development: {
    baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
    eventURL: 'https://games-crash-cash-event.staging.de-2.nsoft.cloud',
    historyURL: 'https://games-read-api.staging.de-2.nsoft.cloud',
    socketConfigURL: 'https://games-crash-cash-registry.staging.de-2.nsoft.cloud',
    sevenUrl: 'https://menhir.staging.gb.nsoftcdn.com',
    sevenAuthUrl: 'https://menhir.gb.nsoftcdn.com',
    upcomingEvents: '/upcoming/',
    gameResults: '/results/game/',
    ticketPayin: '/web/tickets/add.json',
    ticketPayout: '/tickets',
    ticketCancel: '/tickets',
    ticketHistory: '/tickets',
    ticketBarcodeCheck: '/tickets/barcode/',
    ticketRequestIdCheck: '/tickets/',
    globalConfig: '/subscriptions/',
    auth: '/users/b2b/authenticate.json',
    webSocketUrl: 'https://crash-cash-websocket.staging.de-2.nsoft.cloud',
  },
  staging: {
    baseURL: 'https://games-aggregator.staging.de-2.nsoft.cloud',
    eventURL: 'https://games-crash-cash-event.staging.de-2.nsoft.cloud',
    historyURL: 'https://games-read-api.staging.de-2.nsoft.cloud',
    socketConfigURL: 'https://games-crash-cash-registry.staging.de-2.nsoft.cloud',
    sevenUrl: 'https://menhir.staging.gb.nsoftcdn.com',
    sevenAuthUrl: 'https://menhir.gb.nsoftcdn.com',
    upcomingEvents: '/upcoming/',
    gameResults: '/results/game/',
    ticketPayin: '/web/tickets/add.json',
    ticketPayout: '/seven/tickets/actions/payout',
    ticketCancel: '/seven/tickets/actions/cancel',
    ticketHistory: '/tickets',
    ticketBarcodeCheck: '/seven/tickets/barcode',
    ticketRequestIdCheck: '/seven/tickets',
    globalConfig: '/subscriptions/',
    auth: '/users/b2b/authenticate.json',
    webSocketUrl: 'https://crash-cash-websocket.staging.de-2.nsoft.cloud',
  },
  production: {
    baseURL: 'https://games-aggregator.de-2.nsoft.cloud',
    eventURL: 'https://games-crash-cash-event.de-2.nsoft.cloud',
    historyURL: 'https://games-read-api.de-2.nsoft.cloud',
    socketConfigURL: 'https://games-crash-cash-registry.de-2.nsoft.cloud',
    upcomingEvents: '/upcoming/',
    gameResults: '/results/game/',
    ticketPayin: '/seven/tickets/',
    ticketPayout: '/seven/tickets/actions/payout',
    ticketCancel: '/seven/tickets/actions/cancel',
    ticketHistory: '/tickets',
    ticketBarcodeCheck: '/seven/tickets/barcode',
    ticketRequestIdCheck: '/seven/tickets',
    globalConfig: '/subscriptions/',
    webSocketUrl: 'https://crash-cash-websocket.de-2.nsoft.cloud',
  },
};
